import axios from 'axios';
import { convertToFormData } from './misc/FormHelper';


export const indexGame = (page = 1) => {
    const url = `v1/coach/game?page=${page}` 

    return axios.get(url).then(response => response.data)
}

export const indexTeamGame = (teamId) => (page = 1) => {
    const url = `v1/coach/game?page=${page}&team=${teamId}` 

    return axios.get(url).then(response => response.data)
}

export function createGame(form, onUploadProgress, cancelToken) {
    const url = `v1/coach/game` 

    const config = {
        headers: {'Content-Type': 'multipart/form-data'},
        onUploadProgress,
        cancelToken,
    };

    return axios.post(url, convertToFormData(form), config).then(response => response.data)
}

export function getGame(id) {
    const url = `v1/coach/game/${id}`
    return axios.get(url).then(response => response.data?.match)
}