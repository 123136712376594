<template>
    <div>
        <template v-for="item in items" :key="item.id">
            <slot :item="item" />
        </template>

        <div v-if="!isLastPage && !loading" @click="getNextItems">
            LOAD MORE
        </div>
    </div>
</template>

<script>
export default {
    props: {
        callback: {
            required: true,
            type: Function
        },
        isEmpty: {
            required: false,
            type: Boolean
        },
        dataKey: {
            default: "data",
            type: String
        },
        resetWatcher: {
            required: false
        }
    },
    emits: {
        "update:isEmpty": () => true
    },
    data() {
        return {
            items: [],
            currentPage: 0,
            lastPage: undefined,
            loading: false,
        }
    },
    computed: {
        isLastPage() {
            if (!this.lastPage) {
                return false
            }
            return this.currentPage >= this.lastPage
        }
    },
    watch: {
        resetWatcher() {
            this.resetComponentState()
        }
    },
    created() {
        this.getNextItems()
    },
    methods: {
        async getNextItems() {
            if (this.loading || this.isLastPage) {
                return ;
            }
            this.loading = true
            this.$emit('update:isEmpty', false);

            const response = await this.callback(this.currentPage + 1)

            if (!(this.dataKey in response)) {
                throw new Error(`dataKey "${this.dataKey}" not found in response.`)
            }

            this.items = [...this.items, ...response[this.dataKey]]
            this.currentPage = response.meta.current_page
            this.lastPage = response.meta.last_page

            this.loading = false

            if(!this.isEmpty && !this.items.length) {
                this.$emit('update:isEmpty', true);
            }
        },
        resetComponentState() {
            this.items = [];
            this.currentPage = 0;
            this.lastPage = undefined;

            this.getNextItems();
        }
    }
}
</script>