<template>
    <div v-if="teams && teams.length">
        <template v-if="!state.loading">
            <form @submit.prevent="handleSubmit" enctype="multipart/form-data" class="space-y-4">
                <TextInput
                    v-model:value="form.title" 
                    v-model:errors="errors.title" 
                    :label="$t('game.create.name.label')" 
                    :placeholder="$t('game.create.name.placeholder')" 
                />
    
                <TextInput
                    v-model:value="form.description" 
                    v-model:errors="errors.description" 
                    :label="$t('game.create.description.label')" 
                    :placeholder="$t('game.create.description.placeholder')" 
                />

                <SelectInput
                    v-model:value="form.team_id"
                    v-model:errors="errors.team_id"
                    :options="teamOptions"
                    :label="$t('game.create.team')" 
                />

                <div class="flex w-full flex-col items-center justify-center bg-grey-lighter">
                    <label class="w-full md:w-1/2 lg:w-1/3 flex flex-col items-center px-4 py-6 bg-white text-gray-900 rounded-lg shadow-lg tracking-wide uppercase border border-scorelitorange cursor-pointer hover:bg-gray-200 hover:text-black transition duration-200">
                        <svg class="w-8 h-8" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                            <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                        </svg>
                        <span class="mt-2 font-medium text-xs">
                            {{ form.file_name ?? $t('game.create.file.select_file') }}
                        </span>
                        <input 
                            type="file" 
                            ref="file_upload"
                            name="file" 
                            @change="filesChange" 
                            accept="video/mp4,video/x-m4v,video/*"
                            class="hidden"
                        >
                    </label>                        
                </div>
                <template v-if="errors.file?.length">
                    <p 
                        v-for="error in errors.file" 
                        :key="error" 
                        class="mt-2 font-medium text-sm text-red-600"
                    >
                        {{ error }}
                    </p>
                </template>
    
                <button type="submit" class="button">
                    <i18n-t keypath="game.create.submit" />
                </button>
            </form>
        </template>
        <template v-else>
            <div>
                <div class="max-w-xl mx-auto px-4 md:px-6 pt-8">
                    <h1 class="text-black font-semibold text-2xl pt-4 pb-4">{{ $t('game.create.file.loading.is_uploading') }}</h1>
                
                    <div class="relative py-20">
                        <div class="flex mb-2 items-center justify-between">
                            <div>
                                
                            </div>
                            <div class="text-right">
                                <span class="text-xs font-semibold inline-block text-black">
                                    {{ state.progress }}%
                                </span>
                            </div>
                        </div>
                        <div class="overflow-hidden h-4 mb-4 text-xs flex rounded bg-gray-700">
                            <!-- todo: fix progress bar -->
                            <div :style="'width:' + state.progress + '%'" class="progress-bar shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center transition duration-200"></div>
                        </div>
                    </div>

                    <div class="mt-8">
                        <span @click.prevent="cancelRequest" class="block w-full rounded-md shadow-sm">
                            <button type="button" class="button w-full">
                                {{ $t('game.create.file.loading.cancel') }}
                            </button>
                        </span>
                    </div>
                </div>
            </div>
        </template>
    </div>
    <div v-else-if="!teams" class="flex justify-center">
        <svg class="animate-spin w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 12h.01M12 12h.01M16 12h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
    </div>
    <div v-else class="text-xs font-semibold inline-block text-black">
        <i18n-t keypath="game.create.empty">
            <template #click>
                <router-link :to="{ name: 'team.index' }">
                    <span class="text-scorelitred">
                        <i18n-t keypath="game.create.emptyClick" />
                    </span>
                </router-link>
            </template>
        </i18n-t>
    </div>
</template>

<script>
import { defineComponent, nextTick } from 'vue'
import { indexGame } from '@/helper/api/GameHelper';
import TextInput from '@/components/forms/TextInput.vue';
import { createGame } from '@/helper/api/GameHelper';
import { indexTeam } from '@/helper/api/TeamHelper';
import { forcePagination } from "@/helper/api/misc/ForcePaginationHelper"
import axios from 'axios';
import SelectInput from '@/components/forms/SelectInput.vue';

export default defineComponent({
    name: "CreateGame",
    props: {
        initialTeamId: String,
        droppedFiles: FileList,
        isUploading: Boolean,
    },
    emits: {
        "game-created": () => true,
        "update:isUploading": () => true
    },
    methods: {
        indexGame,
        async getTeams() {
            this.teams = await forcePagination(indexTeam, "teams")

            this.form.team_id ??= this.teams[0]?.id
        },
        filesChange(){
            this.form.file = this.$refs.file_upload.files[0];

            this.form.file_name = this.$refs.file_upload.files[0].name;
        },
        setupAxiosCancelToken(){
            this.axios.cancelToken = axios.CancelToken;
            this.axios.source = this.axios.cancelToken.source();
        },
        cancelRequest(){
            this.axios.source.cancel();

            this.setupAxiosCancelToken()
        },
        handleSubmit(){
            this.state.progress = 0;
            this.state.loading = true;
            this.$emit('update:isUploading', true);

            const onUploadProgress = (progressEvent) => { 
                this.state.progress = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
            };

            createGame(this.form, onUploadProgress, this.axios.source.token)
                .then(() =>{
                    this.$emit("game-created");
                    this.resetFormState()
                })
                .catch(err => {
                    if (err.response?.data?.errors) {
                        this.errors = err.response?.data?.errors;
                    } else {
                        throw err
                    }
                })
                .finally(() => {
                    this.$emit('update:isUploading', false);
                    this.state.loading = false;
                    this.state.progress = 0;
                });
        },
        async resetFormState() {
            await nextTick()
            if (this.$refs.file_upload) {
                this.$refs.file_upload.value = ""
            }

            this.form.title = ""
            this.form.description = ""
            this.form.file = undefined
            this.form.file_name = ""
        }
    },
    created() {
        this.form.team_id = this.initialTeamId

        this.getTeams()
        this.setupAxiosCancelToken()
    },
    data() {
        return {
            form: {
                title: "",
                description: "",
                file: undefined,
                file_name: "",
                team_id: undefined,
            },
            errors: {},
            state: {
                loading: false,
                progress: 0
            },
            axios: {
                cancelToken: null,
                source: null,
            },
            teams: null
        }
    },
    watch: {
        droppedFiles(newval) {
            const newFile = newval?.item(0);
            if(newFile && newFile.type.startsWith("video/")) {
                this.form.file = newFile;
                this.form.file_name = newFile.name;
            }
        }
    },
    computed: {
        teamOptions() {
            return this.teams?.map(team => ({
                text: team.name,
                key: team.id
            })) ?? [];
        }
    },
    components: {
    TextInput,
    SelectInput
}
})
</script>

<style scoped>
.progress-bar {
    background-color: #EF4136;
    background: #EF4136;
    background: linear-gradient(to right, #F7941D 0, #EF4136 50%, #F7941D 100%);
    background-position: left center;
    background-size: 200%;
}
</style>