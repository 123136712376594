<template>
    <DashboardWrapper>
        <div class="container mx-auto">
            <div class="mx-4 sm:mx-8">
                <div class="flex items-center mb-4 mx-4 sm:mx-0">
                    <h2 class="text-white uppercase text-xl font-extrabold">
                        <i18n-t keypath="game.index.title" />
                    </h2>
                    <div class="numberCircle h-6 w-6 ml-3 flex justify-center items-center">
                        <span class="text-white text-sm font-medium"></span>
                    </div>
                </div>

                <div class="flex justify-end">
                    <div
                        @click="state.isUploadOpen = true"
                        class="my-auto text-white cursor-pointer flex group"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mr-1 transition-all duration-150 group-hover:text-gray-400 transform group-hover:-translate-y-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"></path>
                        </svg>
                        <p
                            class="mr-6 group-hover:text-gray-400 transition-all duration-150"
                        >
                            <i18n-t keypath="game.index.create" />
                        </p>
                    </div>
                </div>

                <div class="mt-20">
                    <PaginationLoader 
                        :reset-watcher="state.resetGameListIncrementor"
                        :callback="indexGame" 
                        class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-8 mx-4 sm:mx-0"
                        data-key="data"
                        v-slot="{ item: game }"
                    >
                        <router-link :to="{name: 'game.show', params: {id: game.id}}" unselectable="on">
                            <review-item :match="game" :footer="$t('game.create.show')" />
                        </router-link>
                    </PaginationLoader>

                    <Modal v-model:isOpen="state.isUploadOpen" :noLightDismiss="state.isUploading" fileUploadContainer v-slot="{ isDragIncoming }">
                        <h2 class="text-black uppercase text-xl font-extrabold">
                            <i18n-t keypath="game.create.title" />
                        </h2>
                        <div class="relative bg-white p-6 rounded"
                            @dragover="onDragOver"
                            @dragenter="onDragOver"
                            @dragstart="onDragOver"
                            @dragexit="onDragLeave"
                            @dragleave="onDragLeave"
                            @dragend="onDragLeave"
                            @drop="onDrop"
                        >
                            <CreateGame class="relative left-0 top-0" @game-created="refreshGamesList" v-model:isUploading="state.isUploading" :droppedFiles="state.droppedFiles" />
                            <div
                                class="absolute left-0 top-0 w-full h-full bg-white opacity-90 border-2 border-gray-300 border-dashed rounded-md"
                                v-show="isDragIncoming"
                            >
                                &nbsp;
                            </div>
                            <div
                                class="absolute left-0 top-0 h-full w-full text-center flex items-center justify-center"
                                v-show="isDragIncoming"
                            >
                                <span class="text-scorelitred font-medium">
                                    <i18n-t v-if="state.isDragHover" keypath="game.create.drop" />
                                    <i18n-t v-else keypath="game.create.drag" />
                                </span>
                            </div>
                        </div>
                    </Modal>
                </div>
            </div>
        </div>
    </DashboardWrapper>
</template>


<script>
import { defineComponent } from 'vue'
import DashboardWrapper from "@/wrappers/Dashboard";
import PaginationLoader from '@/components/PaginationLoader.vue';
import { indexGame } from '@/helper/api/GameHelper';
import ReviewItem from "@/components/ReviewItem";
import CreateGame from '@/components/game/Create.vue';
import Modal from "@/wrappers/partials/Modal.vue";

export default defineComponent({
    methods: {
        indexGame,
        refreshGamesList() {
            this.state.isUploadOpen = false;
            this.state.resetGameListIncrementor++
        },
        onDragOver(e) {
            if (e.dataTransfer) {
                const items = e.dataTransfer.items;
                if(items.length === 1 && items[0]?.type?.startsWith("video/")) {
                    e.dataTransfer.dropEffect = "copy";
                    this.state.isDragHover = true;
                } else if(items.length > 0) {
                    e.dataTransfer.dropEffect = "none";
                }
            }
        },
        onDragLeave() {
            this.state.isDragHover = false;
        },
        onDrop(e) {
            this.state.isDragHover = false;
            this.state.droppedFiles = e.dataTransfer?.files;
        }
    },
    data() {
        return {
            state: {
                resetGameListIncrementor: 1,
                isUploadOpen: false,
                isUploading: false,
                isDragHover: false,
                droppedFiles: [],
            },
        }
    },
    components: {
        ReviewItem,
        PaginationLoader,
        DashboardWrapper,
        CreateGame,
        Modal
    }
})
</script>
