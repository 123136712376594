
export function convertToFormData(data) {
    if (data instanceof FormData) {
        return data;
    }

    const formData = new FormData();

    for(const key in data) {
        if(data[key] !== undefined){
            formData.set(key, data[key])
        }
    }

    return formData;
}